@import url("https://fonts.googleapis.com/css2?family=Days+One&family=VT323&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Space+Mono:wght@400;700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: black;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* .rec-carousel-wrapper {
  margin-top: 9rem;
} */

